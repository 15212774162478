import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Landing from '../components/HomeLanding';
import styled from 'styled-components';
import BenefitsOfDaye from '../components/HomeBenefits';
import SideEffects from '../components/HomeSideEffects';
import IntroToQuestionaire from '../components/HomeIntroToQuestionaire';
import Rumours from '../components/HomeRumours';
import Subscription from '../components/HomeSubscription';
import CREInfo from '../components/HomeCREInfo';
import MedicalAdvisoryBoard from '../components/HomeMedicalAdvisoryBoard';
import Footer from '../components/Footer';
import '../sass/main.scss';
import 'animate.css/animate.min.css';
import SEO from '../components/Seo';

export default function Home() {
  return (
    <ScrollAnimation animateIn="fadeIn">
      <SEO />
      <PageContainer>
        <Landing />
        <IntroToQuestionaire />
        <Subscription />
        <BenefitsOfDaye />
        <SideEffects />
        <Rumours />
        <CREInfo />
        <MedicalAdvisoryBoard />
        <Footer />
      </PageContainer>
    </ScrollAnimation>
  );
}

const PageContainer = styled.div`
  margin: auto;
  animation: fadein 0.8s;
`;
